import { FC, useEffect, useState } from 'react';
import {
  Text,
  Box,
  Drawer,
  SegmentedControl,
  Center,
  Stack,
  ActionIcon,
  useMantineTheme,
  Grid,
  Flex,
  Loader,
} from '@mantine/core';
import classes from './FiltersDrawer.module.scss';
import { getLabelFromSelectedFilter } from 'utils/bookingsFilters/filters';
import { DynamicFilterOption } from 'interfaces';
import {
  BookingsSortByEnum,
  BookingsViewEnum,
  PebbleButtonsEnum,
  ScheduledActivitySortByEnum,
} from 'enums';
import { SortMenu } from 'components/SortMenu';
import { ClearAllButton } from 'components/ClearAllButton';
import { Actions, trackAction } from 'utils/amplitude';
import classNames from 'classnames';
import { ArrowsDownUp, CaretLeft, SlidersHorizontal, X } from '@phosphor-icons/react';
import ActivitiesFilter from './ActivitiesFilter/ActivitiesFilter';
import VenuesFilter from './VenuesFilter/VenuesFilter';
import MainFilterScreen from './MainFilterScreen/MainFilterScreen';
import TimesFilter from './TimesFilter/TimesFilter';
import WeekdaysFilter from './WeekdaysFilter/WeekdaysFilter';
import { PebbleButtonSet } from 'components/common';

export interface IFiltersDrawer {
  open: boolean;
  onClose(): void;
  activityFilters: DynamicFilterOption[];
  venueFilters: DynamicFilterOption[];
  timeFilters?: DynamicFilterOption[];
  selectedActivity: string;
  selectedVenue: string;
  selectedTimes?: string[];
  onActivityChange(activity: string): void;
  onVenueChange(venue: string): void;
  setSelectedTimesFilter?(times: string[]): void;
  activeSortKey?: BookingsSortByEnum | ScheduledActivitySortByEnum;
  onSortChange?(key: BookingsSortByEnum | ScheduledActivitySortByEnum): void;
  onClearFilters(): void;
  getAppliedTimesFilterLabel?(): string;
  scheduledActivities?: boolean;
  weekdayFilter?: string[];
  setWeekdayFilter?(val: string[]): void;
  getAppliedWeekdayFilterLabel?: () => string;
  weekdayFilters?: DynamicFilterOption[];
  bookingsView?: BookingsViewEnum;
  loading: boolean;
}

export const getMobileFilterTrackEvent = (
  subscriptionEvent: Actions,
  bookingsEvent: Actions,
  scheduledActivitiesEvent: Actions,
  view?: BookingsViewEnum,
) => {
  if (view === BookingsViewEnum.SUBSCRIPTION_BOOKINGS) {
    return trackAction(subscriptionEvent);
  } else if (view === BookingsViewEnum.STANDARD_BOOKINGS) {
    return trackAction(bookingsEvent);
  } else {
    return trackAction(scheduledActivitiesEvent);
  }
};

export const FiltersDrawer: FC<IFiltersDrawer> = ({
  open,
  onClose,
  activityFilters,
  venueFilters,
  timeFilters,
  selectedActivity,
  selectedVenue,
  selectedTimes,
  onActivityChange,
  onVenueChange,
  setSelectedTimesFilter,
  activeSortKey,
  onSortChange,
  onClearFilters,
  getAppliedTimesFilterLabel,
  scheduledActivities,
  weekdayFilter,
  setWeekdayFilter,
  getAppliedWeekdayFilterLabel,
  weekdayFilters,
  bookingsView,
  loading,
}) => {
  const theme = useMantineTheme();

  const [currentInnerModalComponent, setCurrentInnerModalComponent] = useState<string | null>(null);

  const subscriptionBooking = bookingsView === BookingsViewEnum.SUBSCRIPTION_BOOKINGS;

  const [menu, setMenu] = useState('filter');

  useEffect(() => {
    if (subscriptionBooking) {
      setMenu('filter');
    }
  }, [setMenu, subscriptionBooking]);

  const selectedActivityLabel = selectedActivity
    ? getLabelFromSelectedFilter(selectedActivity, activityFilters)
    : activityFilters[0]?.label;

  const selectedVenueLabel = selectedVenue
    ? getLabelFromSelectedFilter(selectedVenue, venueFilters)
    : venueFilters[0]?.label;

  const selectedTimesLabel =
    getAppliedTimesFilterLabel && setSelectedTimesFilter && timeFilters
      ? getAppliedTimesFilterLabel()
      : null;

  const selectedWeekdayLabel =
    getAppliedWeekdayFilterLabel && setWeekdayFilter && weekdayFilter
      ? getAppliedWeekdayFilterLabel()
      : null;

  const getFilterContent = () => {
    if (menu === 'filter') {
      switch (currentInnerModalComponent) {
        case 'activities':
          return (
            <ActivitiesFilter
              activityFilters={activityFilters}
              selectedActivity={selectedActivity}
              onActivityChange={onActivityChange}
              bookingsView={bookingsView}
            />
          );
        case 'venues':
          return (
            <VenuesFilter
              venueFilters={venueFilters}
              selectedVenue={selectedVenue}
              onVenueChange={onVenueChange}
              bookingsView={bookingsView}
            />
          );
        case 'times':
          return (
            <TimesFilter
              setSelectedTimesFilter={setSelectedTimesFilter}
              selectedTimes={selectedTimes}
              timeFilters={timeFilters}
              bookingsView={bookingsView}
            />
          );
        case 'days':
          return (
            <WeekdaysFilter
              setWeekdayFilter={setWeekdayFilter}
              selectedWeekdayFilter={weekdayFilter}
              weekdayFilters={weekdayFilters}
            />
          );
        default:
          return (
            <MainFilterScreen
              setCurrentInnerModalComponent={setCurrentInnerModalComponent}
              selectedActivityLabel={selectedActivityLabel}
              selectedVenueLabel={selectedVenueLabel}
              selectedTimesLabel={selectedTimesLabel}
              selectedWeekdayLabel={selectedWeekdayLabel}
              showDaysFilter={subscriptionBooking}
            />
          );
      }
    } else if (activeSortKey && onSortChange) {
      return (
        <SortMenu
          activeSortKey={activeSortKey}
          onSortChange={onSortChange}
          ascKey={
            scheduledActivities
              ? ScheduledActivitySortByEnum.DATE_ASC
              : BookingsSortByEnum.SESSION_DATE_ASC
          }
          descKey={
            scheduledActivities
              ? ScheduledActivitySortByEnum.DATE_DESC
              : BookingsSortByEnum.SESSION_DATE_DESC
          }
        />
      );
    }
  };

  const handleClearButton = () => {
    if (menu === 'filter') {
      switch (currentInnerModalComponent) {
        case 'activities':
          return onActivityChange('All activities');
        case 'venues':
          return onVenueChange('All locations');
        case 'times':
          return setSelectedTimesFilter ? setSelectedTimesFilter([]) : null;
        case 'days':
          return setWeekdayFilter ? setWeekdayFilter([]) : null;
        default:
          return onClearFilters();
      }
    } else {
      return;
    }
  };

  return (
    <Drawer
      opened={open}
      onClose={() => {
        onClose();
      }}
      position="bottom"
      classNames={{
        body: classNames(classes.standardDrawerBody, {
          [classes.drawerBody]: currentInnerModalComponent,
        }),
        header: classes.drawerHeader,
        close: classes.drawerCloseButton,
        content: classNames(classes.drawerContent, {
          [classes.innerDrawerContent]: currentInnerModalComponent,
        }),
      }}
      withCloseButton={false}
    >
      {/* Header */}
      <Stack gap={0}>
        {!currentInnerModalComponent && (
          <ActionIcon
            title="Close"
            variant="outline"
            onClick={onClose}
            className={classes.closeButton}
          >
            <X size={26} color={theme.colors.gray[6]} weight="bold" />
          </ActionIcon>
        )}
        <Grid className={classes.header} align="center">
          {currentInnerModalComponent && (
            <Grid.Col span={4}>
              <PebbleButtonSet
                btnVariant={PebbleButtonsEnum.TERTIARY}
                size="md"
                pl={0}
                leftSection={<CaretLeft weight="bold" />}
                onClick={() => {
                  setCurrentInnerModalComponent(null);
                }}
              >
                Back
              </PebbleButtonSet>
            </Grid.Col>
          )}

          {currentInnerModalComponent === null && (
            <Grid.Col span={currentInnerModalComponent ? 4 : 12}>
              <Flex w="100%" justify="space-between" align="center">
                <Text
                  fw={700}
                  size={theme.fontSizes.xl}
                  c={theme.colors.blue[8]}
                  ml="xs"
                  mt="lg"
                  mb={bookingsView === BookingsViewEnum.STANDARD_BOOKINGS ? '0' : 'sm'}
                >
                  {menu === 'filter' ? 'Filter activities' : 'Sort activities'}
                </Text>
              </Flex>
            </Grid.Col>
          )}

          <Grid.Col span={currentInnerModalComponent ? 4 : 12}>
            <Center>
              <SegmentedControl
                radius="lg"
                classNames={{
                  root: classes.controlRoot,
                  label: classes.controlLabel,
                  control: classNames(classes.control, {
                    [classes.controlRootHidden]: currentInnerModalComponent !== null,
                  }),
                }}
                transitionDuration={0}
                onChange={(value: string) => {
                  setMenu(value);
                  const action =
                    value === 'filter'
                      ? Actions.BOOKINGS_FILTERS_MOBILE
                      : Actions.BOOKINGS_SORT_MOBILE;
                  trackAction(action);
                }}
                value={menu}
                size="lg"
                data={
                  subscriptionBooking
                    ? [
                        {
                          label: (
                            <Flex align="center" mt="4px" justify="center">
                              <SlidersHorizontal size={24} color="white" weight="fill" />

                              <Text ml="xs" fw={700}>
                                FILTER
                              </Text>
                            </Flex>
                          ),
                          value: 'filter',
                        },
                      ]
                    : [
                        {
                          label: (
                            <Flex align="center" justify="center" mt="3px">
                              <SlidersHorizontal size={24} color="white" weight="fill" />
                              <Text ml="xs" fw={700}>
                                FILTER
                              </Text>
                            </Flex>
                          ),
                          value: 'filter',
                        },
                        {
                          label: (
                            <Flex align="center" mt="4px" justify="center">
                              <ArrowsDownUp size={24} />
                              <Text ml="xs" fw={700}>
                                SORT
                              </Text>
                            </Flex>
                          ),
                          value: 'sort',
                        },
                      ]
                }
              />
            </Center>
          </Grid.Col>
        </Grid>
      </Stack>
      {/* Middle component */}
      {loading ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        getFilterContent()
      )}

      <div
        className={classNames({
          [classes.drawerContentWithFade]: currentInnerModalComponent !== null,
        })}
      ></div>
      {/* Bottom component */}
      <Box className={classes.buttonWrapper}>
        <Flex align="center" justify="space-between">
          {menu !== 'sort' && (
            <ClearAllButton
              onClick={() => {
                handleClearButton();
                getMobileFilterTrackEvent(
                  Actions.SUB_FILTER_CLEAR_MOBILE,
                  Actions.BOOKINGS_FILTERS_MOBILE,
                  Actions.SCHEDULED_FILTER_CLEAR_MOBILE,
                  bookingsView,
                );
              }}
              filterComponent={currentInnerModalComponent}
            />
          )}
          <PebbleButtonSet
            btnVariant={PebbleButtonsEnum.PRIMARY}
            size="md"
            fullWidth={menu === 'sort'}
            onClick={() => {
              onClose();
              getMobileFilterTrackEvent(
                Actions.SUB_SHOWRESULTS_MOBILE,
                Actions.BOOKING_SHOWRESULTS_MOBILE,
                Actions.SCHEDULED_SHOWRESULTS_MOBILE,
                bookingsView,
              );
            }}
          >
            Show results
          </PebbleButtonSet>
        </Flex>
      </Box>
    </Drawer>
  );
};
