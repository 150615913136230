export enum Actions {
  SUPPLIER_LOGIN_EMAIL = 'supplier_login_email',
  SUPPLIER_LOGIN_PASSWORD = 'supplier_login_password',
  CLAIM_LISTING_SUPPLIER = 'claim_listing_supplier',
  SUPPLIER_REGISTER = 'supplier_register',
  SUPPLIER_REGISTERS_SELECTED = 'supplier_registers_selected',
  SUPPLIER_FORGOT_PASSWORD = 'supplier_forgot_password',
  SUPPLIER_REGISTER_LOGIN = 'supplier_register_login',
  SUPPLIER_REGISTER_EMAIL = 'supplier_register_email',
  SUPPLIER_REGISTER_PASSWORD = 'supplier_register_password',
  SUPPLIER_REGISTER_PASSWORD_CONFIRM = 'supplier_register_password_confirm',
  SUPPLIER_REGISTER_TERMS = 'supplier_register_terms',
  SUPPLIER_REGISTER_PASA = 'supplier_register_PASA',
  SUPPLIER_REGISTER_CTA = 'supplier_register_CTA',
  SUPPLIER_VERIFY_CODE = 'supplier_verify_code',
  SUPPLIER_VERIFY_RESEND = 'supplier_verify_resend',
  SUPPLIER_VERIFY_CTA = 'supplier_verify_CTA',
  SUPPLIER_ONBOARD_CONTACT = 'supplier_onboard_contact',
  SUPPLIER_ONBOARD_LOGOUT = 'supplier_onboard_logout',
  SUPPLIER_SEARCH = 'supplier_search',
  SUPPLIER_SWITCH = 'supplier_switch',
  SUPPLIER_HOME = 'supplier_home',
  SUPPLIER_ADD_ACTIVITY = 'supplier_add_activity',
  SUPPLIER_SCHEDULED = 'supplier_scheduled',
  SUPPLIER_BOOKINGS = 'supplier_bookings',
  SUPPLIER_SETTINGS = 'supplier_settings',
  SUPPLIER_INTEGRATIONS = 'supplier_integrations',
  SUPPLIER_CONTACT = 'supplier_contact',
  SUPPLIER_HELP_CENTRE = 'supplier_help_centre',
  SCHEDULE_UPDATE_REQUEST = 'schedule_update_request',

  SCHEDULED_ALL = 'scheduled_all',
  SCHEDULED_UPCOMING = 'scheduled_upcoming',
  SCHEDULED_PAST = 'scheduled_past',
  SCHEDULED_ACTIVITIES_FILTER = 'scheduled_activities_filter',
  SCHEDULED_VENUES_FILTER = 'scheduled_venues_filter',
  SCHEDULED_CLEAR_FILTERS = 'scheduled_clear_filters',
  SCHEDULED_NAME = 'scheduled_name',
  SCHEDULED_VIEW = 'scheduled_view',
  SCHEDULED_EDIT = 'scheduled_edit',
  SCHEDULED_SESSIONS = 'scheduled_sessions',
  SCHEDULED_DELETE = 'scheduled_delete',
  SCHEDULED_CLONE = 'scheduled_clone',
  SCHEDULED_PUBLISHED = 'scheduled_published',
  SCHEDULED_UNPUBLISHED = 'scheduled_unpublished',
  SUPPLIER_TERMS = 'supplier_terms',
  KEBAB = 'kebab',

  BOOKINGS_DATE_FILTER = 'bookings_date_filter',
  BOOKINGS_CALENDAR_DATE_FILTER = 'bookings_calendar_date_filter',
  BOOKINGS_ACTIVITIES_FILTER = 'bookings_activities_filter',
  BOOKINGS_VENUES_FILTER = 'bookings_venues_filter',
  BOOKINGS_CLEAR_FILTERS = 'bookings_clear_filters',
  BOOKINGS_SORT = 'bookings_sort',
  BOOKINGS_EXPORT = 'bookings_export',
  BOOKINGS_EXPORT_CANCEL = 'bookings_export_cancel',
  BOOKINGS_EXPORT_AGREE = 'bookings_export_agree',
  BOOKING_CHILD_NAME = 'booking_child_name',
  BOOKING_GUARDIAN_CLOSE = 'booking_guardian_close',
  BOOKING_WHATSAPP = 'booking_whatsapp',
  BOOKING_EMAIL = 'booking_email',
  BOOKINGS_TIME_FILTER = 'bookings_time_filter',
  BOOKINGS_TIME_CHOSEN = 'bookings_time_chosen',

  BOOKINGS_EMAIL_CUSTOMERS = 'bookings_email_customers',
  BOOKINGS_EMAILS_OPT_IN = 'bookings_emails_opt_in',
  BOOKINGS_EMAILS_ALL = 'bookings_emails_all',
  BOOKINGS_MAIL_CLIENT = 'bookings_mail_client',
  BOOKINGS_COPY_ALL_EMAILS = 'bookings_copy_all_emails',
  BOOKINGS_COPY_EMAIL = 'bookings_copy_email',

  LAST_30_DAYS = 'last_30_days',
  NEXT_15_DAYS = 'next_15_days',
  REGISTER_SEARCH = 'register_search',
  REGISTER_SEARCH_TYPE = 'register_search_type',
  CLASS_REGISTER_SELECT = 'class_register_select',
  ATTENDEE_NAME_REGISTER_SORT = 'attendee_name_register_sort',
  ATTENDEE_LAST_NAME_REGISTER_SORT = 'attendee_last_name_register_sort',
  MEDICAL_REGISTER_SORT = 'medical_register_sort',
  ATTENDANCE_REGISTER_SORT = 'attendance_register_sort',
  PHOTO_REGISTER_SORT = 'photo_register_sort',
  AGE_REGISTER_SORT = 'age_register_sort',
  TICKET_REGISTER_SORT = 'ticket_register_sort',
  ADDON_ONS_REGISTER_SORT = 'addon_ons_register_sort',
  BOOKING_ID_SORT = 'booking_id_sort',
  REGISTER_FURTHER_INFORMATION = 'register_further_information',
  ATTENDED = 'attended',
  ABSENT = 'absent',
  REGISTER_GO_TO_AO = 'register_go_to_ao',
  REGISTER_GO_TO_GROUPED = 'register_go_to_grouped',

  GROUPED_REGISTER_SELECT = 'grouped_register_select',

  TERM_NAME = 'term_name',
  TERM_START = 'term_start',
  TERM_END = 'term_end',
  TERM_CALENDAR = 'term_calendar',
  ADD_TERM = 'add_term',
  CANCEL_TERM = 'cancel_term',
  VIEW_TERM = 'view_term',
  DELETE_TERM = 'delete_term',

  STAY_ON_PAGE = 'stay_on_page',
  LEAVE_PAGE = 'leave_page',

  ACTIVITY_DETAILS_TAB = 'details_tab_activity',
  ACTIVITY_SCHEDULE_TAB = 'schedule_tab_activity',
  ACTIVITY_TICKETS_TAB = 'tickets_tab_activity',
  ACTIVITY_CAPACITY_TAB = 'sessions_tab_activity',
  ACTIVITY_CONTACT_TAB = 'contact_tab_activity',
  TAB_NEXT = 'tab_next',
  TAB_BACK = 'tab_back',
  SAVED = 'saved',
  ACTIVITY_CANCEL = 'activity_cancel',
  INSTANT_BOOK = 'instant_book',
  ACTIVITY_TYPE = 'activity_type',
  MIN_AGE = 'min_age',
  MAX_AGE = 'max_age',
  BUILDING_NAME = 'building_name',
  POSTCODE = 'postcode',
  FIND_ADDRESS = 'find_address',
  ACTIVITY_NAME = 'activity_name',
  DESCRIPTION = 'description',
  GUARDIAN_BRING = 'guardian_bring',
  WE_PROVIDE = 'we_provide',
  ADD_IMAGE_CTA = 'add_image_CTA',
  IMAGE_BROWSE = 'image_browse',
  IMAGE_CANCEL = 'image_cancel',
  IMAGE_DRAG = 'image_drag',
  IMAGE_CROP = 'image_crop',
  IMAGE_ZOOM = 'image_zoom',
  IMAGE_MAIN = 'image_main',
  IMAGE_NEXT = 'image_next',
  IMAGE_APPROVAL = 'image_approval',
  IMAGE_CONFIRM = 'image_confirm',
  IMAGE_DELETE = 'image_delete',
  ACTIVITY_START_DATE_SELECT = 'activity_start_date_select',
  ACTIVITY_END_DATE_SELECT = 'activity_end_date_select',
  TIME_ENTER = 'time_enter',
  TIME_ADD = 'time_add',
  TIME_DELETE = 'time_delete',
  TIME_CLEAR = 'time_clear',
  TIME_COPY = 'time_copy',
  TIME_COPY_APPLY = 'time_copy_apply',
  BOOKING_TYPE_BLOCK = 'booking_type_block',
  BOOKING_TYPE_ALL_DAYS = 'booking_type_all_days',
  BOOKING_TYPE_RECURRING = 'booking_type_recurring',
  BOOKING_TYPE_INDIVIDUAL = 'booking_type_individual',
  DAY_SELECT = 'day_select',
  HIDE_END_TIME = 'hide_end_time',
  TICKET_NAME = 'ticket_name',
  TICKET_DESCRIPTION = 'ticket_description',
  TICKET_COST = 'ticket_cost',
  SUB_PER_SESSION_COST = 'sub_per_session_cost',
  CHARGE_PER_SESSION = 'charge_per_session',
  CHARGE_SET_PRICE = 'charge_set_price',
  FREE_TICKET = 'free_ticket',
  TICKET_RESTRICTIONS = 'ticket_restrictions',
  STOP_FIRST_SESSION = 'stop_first_session',
  SET_AVAILABILITY_DATE = 'set_availability_date',
  ADD_ANOTHER_TICKET = 'add_another_ticket',
  DELETE_TICKET = 'delete_ticket',
  CAPACITY = 'capacity',
  DATE_DELETE = 'date_delete',
  WEBSITE_ADD = 'website_add',
  ACTIVITY_EMAIL_ADD = 'activity_email_add',
  ACTIVITY_FB_ADD = 'activity_fb_add',
  ACTIVITY_TWIT_ADD = 'activity_Twit_add',
  ACTIVITY_INSTA_ADD = 'activity_insta_add',
  ACTIVITY_PUBLISH_DONE = 'activity_publish_done',
  ACTIVITY_PUBLISH_NEW_ACTIVITY = 'activity_publish_new_activity',
  ACTIVITY_QUICK_VIEW = 'activity_quickview',

  GUIDE_PRICE = 'guide_price',

  BACK_TO_ACTIVITIES = 'back_to_activities',
  VIEW_PAGE_EDIT = 'view_page_edit',

  YOUR_DETAILS = 'your_details',
  PAYMENT_DETAILS = 'payment_details',
  SUPPLIER_PAGE_DETAILS = 'supplier_page_details',
  CLOSE_ACCOUNT = 'close_account',
  SUPPLIER_KEY_CONTACT = 'supplier_key_contact',
  COMPANY = 'company',
  SUPPLIER_EMAIL = 'supplier_email',
  SUPPLIER_PHONE = 'supplier_phone',
  SUPPLIER_WEBSITE = 'supplier_website',
  SUPPLIER_ADDRESS_1 = 'supplier_address_1',
  SUPPLIER_ADDRESS_2 = 'supplier_address_2',
  SUPPLIER_CITY = 'supplier_city',
  SUPPLIER_POSTCODE = 'supplier_postcode',
  SUPPLIER_SAVE = 'supplier_save',
  SUPPLIER_CANCEL = 'supplier_cancel',
  PAYMENT_DETAILS_CTA = 'payment_details_CTA',
  ADD_IMAGE_CTA_SUPPLIER = 'add_image_CTA_supplier',
  IMAGE_BROWSE_SUPPLIER = 'image_browse_supplier',
  IMAGE_CANCEL_SUPPLIER = 'image_cancel_supplier',
  IMAGE_CROP_SUPPLIER = 'image_crop_supplier',
  IMAGE_ZOOM_SUPPLIER = 'image_zoom_supplier',
  IMAGE_APPROVAL_SUPPLIER = 'image_approval_supplier',
  IMAGE_CONFIRM_SUPPLIER = 'image_confirm_supplier',
  IMAGE_NEXT_SUPPLIER = 'image_next_supplier',
  IMAGE_DELETE_SUPPLIER = 'image_delete_supplier',
  ADD_IMAGE_LOGO = 'add_image_logo',
  IMAGE_DELETE_LOGO = 'image_delete_logo',
  IMAGE_BROWSE_LOGO = 'image_browse_logo',
  IMAGE_CANCEL_LOGO = 'image_cancel_logo',
  IMAGE_CROP_LOGO = 'image_crop_logo',
  IMAGE_NEXT_LOGO = 'image_next_logo',
  IMAGE_CONFIRM_LOGO = 'image_confirm_logo',
  IMAGE_ZOOM_LOGO = 'image_zoom_logo',
  ABOUT_YOU = 'about_you',
  SUPPLIER_MIN_AGE = 'supplier_min_age',
  SUPPLIER_MAX_AGE = 'supplier_max_age',
  POPULAR_ACTIVITIES_1 = 'popular_activities_1',
  POPULAR_ACTIVITIES_2 = 'popular_activities_2',
  POPULAR_ACTIVITIES_3 = 'popular_activities_3',
  POPULAR_ACTIVITIES_4 = 'popular_activities_4',
  SUPPLIER_PAGE_SAVE = 'supplier_page_save',
  SUPPLIER_PAGE_CANCEL = 'supplier_page_cancel',
  SUPPLIER_PREVIEW = 'supplier_preview',
  CLOSE_ACCOUNT_CTA = 'close_account_CTA',
  CONSENT_DOCUMENTS = 'consent_documents',
  CONSENT_DOCUMENTS_NAME = 'consent_documents_name',
  CONSENT_DOCUMENTS_LINK = 'consent_documents_link',
  CONSENT_DOCUMENTS_SAVE = 'consent_documents_save',
  CONSENT_DOCUMENTS_CANCEL = 'consent_documents_cancel',
  CONSENT_DOCUMENTS_REMOVE = 'consent_documents_remove',

  CATEGORY_ADD = 'category_add',
  CATEGORY_REMOVE = 'category_remove',
  SUGGESTED_CATEGORY = 'suggested_category',

  CALENDAR_FORWARD = 'calendar_forward',
  CALENDAR_BACK = 'calendar_back',

  MINUS_ONE = 'minus_one',
  MINUS_FIVE = 'minus_five',
  PLUS_ONE = 'plus_one',
  PLUS_FIVE = 'plus_five',
  SESSION_PLUS = 'session_plus',
  SESSION_MINUS = 'session_minus',
  SESSION_DELETE = 'session_delete',
  SESSION_DELETE_UNDO = 'session_delete_undo',

  ATTENDEE_EMAIL_SELECT_ALL = 'attendee_email_select_all',
  ATTENDEE_EMAIL_TIME_SLOT = 'attendee_email_time_slot',
  ATTENDEE_EMAIL_SHOW_EMAILS = 'attendee_email_show_emails',
  ATTENDEE_EMAIL_CANCEL = 'attendee_email_cancel',
  ATTENDEE_EMAIL_AGREE = 'attendee_email_agree',
  ATTENDEE_EMAIL_COPY_EMAILS = 'attendee_email_copy_emails',
  ATTENDEE_EMAIL_COPY_CLIENT = 'attendee_email_copy_client',
  ATTENDEE_EMAIL_BACK = 'attendee_email_back',

  BOOKING_TYPE_BI = 'booking_type_BI',
  BOOKING_TYPE_S = 'booking_type_S',
  BOOKING_TYPE_SFAQ = 'booking_type_SFAQ',
  SUBS_DETAILS = 'subs_details',
  SUBS_OFFDAY = 'subs_offday',
  TASTER_ON = 'taster_on',
  SUBS_PRICING_GUIDE = 'subs_pricing_guide',
  SAVE_DRAFT = 'save_draft',
  SAVE_PUBLISH = 'save_publish',
  SUBSCRIPTIONS_SELECTED = 'subscriptions_selected',
  SUBS_ACTIVITY_OPEN = 'subs_activity_open',
  SUB_CANCELLED = 'sub_cancelled',
  SUB_GUARDIAN = 'sub_guardian',
  PARENTS_REQUESTED = 'parents_requested',
  FAILED_PAYMENTS = 'failed_payments',
  CHANGE_CIRCUMSTANCES = 'change_circumstances',
  OTHER_REASON = 'other_reason',
  CANCEL_CANCEL = 'cancel_cancel',
  CANCEL_CONFIRM = 'cancel_confirm',
  SUBS_MENU = 'subs_menu',
  SUBS_PAUSE = 'subs_pause',
  SUBS_PAUSE_CONFIRM = 'subs_pause_confirm',
  SUBS_RESUME = 'subs_resume',
  SUBS_RESUME_CONFIRM = 'subs_resume_confirm',
  SUBS_CANCEL = 'subs_cancel',
  SUBS_HISTORY = 'subs_view_history',

  BOOKINGS_VIEW_DETAILS = 'bookings_view_details',
  DETAILS_MOBILE = 'details_mobile',
  DETAILS_EMAIL = 'details_email',
  DETAILS_CLOSE = 'details_close',
  ADDITIONAL_INFO = 'additional_info',
  ADDITIONAL_INFO_ON = 'additional_info_on',
  ADDITIONAL_INFO_OFF = 'additional_info_off',
  ADDITIONAL_INFO_AGREE = 'additional_info_agree',
  ADDITIONAL_INFO_CANCEL = 'additional_info_cancel',
  BOOKING_QUESTIONS_GO_TO_SCHEDULED_ACTIVITIES = 'booking_questions_go_to_scheduled_activities',

  LOCATION_INPERSON = 'location_inperson',
  LOCATION_ONLINE = 'location_online',
  PREBOOKING_DETAILS = 'prebooking_detail',
  POSTBOOKING_DETAILS = 'postbooking_detail',
  PREBOOKING_INFO = 'prebooking_info',
  POSTBOOKING_INFO = 'postbooking_info',
  SCHEDULED_LOCATION_SELECT = 'scheduled_location_select',
  SCHEDULED_ACTIVITY_SELECT = 'scheduled_activity_select',
  SCHEDULED_TIME_SELECT = 'scheduled_time_select',

  CREATE_SUPPLIER_IFRAME = 'create_supplier_iframe',
  CREATE_ACTIVITY_IFRAME = 'create_activity_iframe',
  CREATE_CHECKOUT_IFRAME = 'create_checkout_iframe',
  CATEGORY_SELECT_IFRAME = 'category_select_iframe',
  ACTIVITY_SELECT_IFRAME = 'activity_select_iframe',
  THEME_SELECT_IFRAME = 'theme_select_iframe',
  SMALL_IFRAME = 'small_iframe',
  MEDIUM_IFRAME = 'medium_iframe',
  LARGE_IFRAME = 'large_iframe',
  CUSTOM_IFRAME = 'custom_iframe',
  GENERATE_IFRAME = 'generate_iframe',
  COPY_IFRAME = 'copy_iframe',
  IFRAME_GENERATOR = 'iframe_generator',

  YOUR_PEBBLE_REVIEW = 'your_pebble_review',

  DASH_PAYMENT_DETAILS = 'dash_payment_details',
  DASH_BUSINESS_DETAILS = 'dash_business_details',
  DASH_SUPPLIER_DETAILS = 'dash_supplier_page',
  DASH_ADD_ACTIVITY = 'dash_add_activity',
  DASH_CALENDAR = 'dash_calendar',
  DASH_UPCOMING_CAPACITY = 'dash_upcoming_capacity',
  DASH_REGISTER = 'dash_register',
  DASH_NO_CAPACITY = 'dash_no_capacity',
  DASH_SOLD_OUT = 'dash_sold_out',
  DASH_NEAR_CAPACITY = 'dash_near_capacity',
  DASH_DATE_PICKER = 'dash_date_picker',
  DASH_ACTIVITY_PICKER = 'dash_activity_picker',
  DASH_EXPORT_FINANCE = 'dash_export_finance',

  AGE_RANGE_EDIT_WITH_BOOKINGS = 'age_range_edit_with_bookings',
  ACTIVITY_EDIT_ACKNOWLEDGE = 'activity_edit_acknowledge',

  PONCHOPAY_SETUP = 'ponchopay_setup',
  PONCHOPAY_SETUP_SAVE = 'ponchopay_setup_save',

  TEAM_MEMBER_SELECT = 'team_member_select',
  TEAM_MEMBER_DESELECT = 'team_member_deselect',

  TEAM_MEMBERS = 'team_members',
  TEAM_MEMBER_ADD = 'team_member_add',
  TEAM_MEMBER_NAME = 'team_member_name',
  TEAM_MEMBER_EMAIL = 'team_member_email',
  TEAM_MEMBER_ADD_ADMIN = 'team_member_add_admin',
  TEAM_MEMBER_CANCEL = 'team_member_cancel',
  TEAM_MEMBER_SEND = 'team_member_send',
  TEAM_MEMBER_ACTIVE_ADMIN = 'team_member_active_admin',
  TEAM_MEMBER_PENDING_DELETE = 'team_member_pending_delete',
  TEAM_MEMBER_ACTIVE_DELETE = 'team_member_active_delete',

  REGISTER_SESSION_EXPORT = 'register_session_export',
  GROUPED_REGISTER_SESSION_EXPORT = 'grouped_register_session_export',

  PREVIEW_BOOKING_QUESTIONS = 'preview_booking_questions',
  ADD_A_QUESTION_CARD = 'add_a_question_card',
  ADD_QUESTION_CTA = 'add_question_cta',
  FIND_A_QUESTION = 'find_a_question',
  QUESTION_NOT_IN_LIST = 'question_not_in_list',
  DRAG_QUESTION = 'drag_question',
  DELETE_QUESTION = 'delete_question',
  DELETE_QUESTION_MODAL_CONFIRM = 'delete_question_modal_confirm',
  DELETE_QUESTION_CANCEL = 'delete_question_cancel',
  DELETE_QUESTION_CLOSE = 'delete_question_close',
  STATUS_TOOLTIP = 'status_tooltip',
  BOOKING_QUESTIONS_SETTINGS = 'booking_questions_settings',

  ADD_ATTENDEE = 'add_attendee',
  ADD_ATTENDEE_CAPACITY = 'add_attendee_capacity',
  ADD_ATTENDEE_CAPACITY_CLOSE = 'add_attendee_capacity_close',
  ADD_ATTENDEE_CONTINUE = 'add_attendee_continue',
  ADD_ATTENDEE_CLOSE = 'add_attendee_close',
  ADD_ATTENDEE_BACK = 'add_attendee_back',
  ADD_ATTENDEE_PAYMENT_PAID = 'add_attendee_payment_paid',
  ADD_ATTENDEE_PAYMENT_NONPAID = 'add_attendee_payment_nonpaid',
  ADD_ATTENDEE_PAYMENT_CLOSE = 'add_attendee_payment_close',
  ADD_ATTENDEE_PAYMENT_BACK = 'add_attendee_payment_back',
  PAID_INVITE_EMAIL = 'paid_invite_email',
  PAID_INVITE_CUSTOMER = 'paid_invite_customer',
  PAID_INVITE_SEND = 'paid_invite_send',
  PAID_INVITE_CLOSE = 'paid_invite_close',
  PAID_INVITE_BACK = 'paid_invite_back',
  PAID_SUCCESS_DONE = 'paid_success_done',
  PAID_SUCCESS_CAPACITY = 'paid_success_capacity',
  PAID_SUCCESS_CLOSE = 'paid_success_close',
  NONPAID_BLOCK_BLOCK = 'nonpaid_block_block',
  NONPAID_BLOCK_SINGLE = 'nonpaid_block_single',
  NONPAID_BLOCK_CLOSE = 'nonpaid_block_close',
  NONPAID_BLOCK_BACK = 'nonpaid_block_back',
  NONPAID_CUSTOMER_NAME = 'nonpaid_customer_name',
  NONPAID_CUSTOMER_EMAIL = 'nonpaid_customer_email',
  NONPAID_CUSTOMER_PHONE = 'nonpaid_customer_phone',
  NONPAID_CUSTOMER_NEXT = 'nonpaid_customer_next',
  NONPAID_CUSTOMER_CLOSE = 'nonpaid_customer_close',
  NONPAID_CUSTOMER_BACK = 'nonpaid_customer_back',
  NONPAID_TICKETS_PLUS = 'nonpaid_tickets_plus',
  NONPAID_TICKETS_MINUS = 'nonpaid_tickets_minus',
  NONPAID_TICKETS_NEXT = 'nonpaid_tickets_next',
  NONPAID_TICKETS_CLOSE = 'nonpaid_tickets_close',
  NONPAID_TICKETS_BACK = 'nonpaid_tickets_back',
  NONPAID_ATTENDEE_ACCORDION = 'nonpaid_attendee_accordion',
  NONPAID_ATTENDEE_NAME = 'nonpaid_attendee_name',
  NONPAID_ATTENDEE_DOB = 'nonpaid_attendee_dob',
  NONPAID_ATTENDEE_NEXT = 'nonpaid_attendee_next',
  NONPAID_ATTENDEE_CLOSE = 'nonpaid_attendee_close',
  NONPAID_ATTENDEE_BACK = 'nonpaid_attendee_back',
  NONPAID_REVIEW_CONFIRM = 'nonpaid_review_confirm',
  NONPAID_REVIEW_CLOSE = 'nonpaid_review_close',
  NONPAID_REVIEW_BACK = 'nonpaid_review_back',
  NONPAID_SUCCESS_DONE = 'nonpaid_success_done',
  NONPAID_SUCCESS_CLOSE = 'nonpaid_success_close',
  NONPAID_ADULT = 'nonpaid_adult',
  REGISTER_MANUALLY_ADDED = 'register_manually_added',

  MOVE_ATTENDEE = 'move_attendee',
  MOVE_ATTENDEE_SELECT = 'move_attendee_select',
  MOVE_ATTENDEE_BEGIN = 'move_attendee_begin',
  MOVE_ATTENDEE_CANCEL = 'move_attendee_cancel',
  MOVE_SAME_ACTIVITY = 'move_same_activity',
  MOVE_FIND_NEW_ACTIVITY = 'move_find_new_activity',
  MOVE_FILTER_TERM_ADDED = 'move_filter_term_added',
  MOVE_FILTER_TERM_PLUS = 'move_filter_term_plus',
  MOVE_FILTER_TERM_REMOVE = 'move_filter_term_remove',
  MOVE_SCHEDULE = 'move_schedule',
  MOVE_DATE = 'move_date',
  MOVE_TIME = 'move_time',
  MOVE_UPDATE_CAPACITY = 'move_update_capacity',
  MOVE_DATETIME_NEXT = 'move_datetime_next',
  MOVE_CONFIRM = 'move_confirm',
  MOVE_DONE = 'move_done',
  MOVE_BACK = 'move_back',
  MOVE_CLOSE = 'move_close',

  BOOKINGS_TEXT_SEARCH = 'bookings_text_search',
  BOOKINGS_TEXT_SEARCH_CLEAR = 'bookings_text_search_clear',

  ENROL_CUSTOMERS_CTA = 'enrol_customers_CTA',
  ENROL_PREVIOUS = 'enrol_previous',
  ENROL_MANUAL = 'enrol_manual',
  ENROL_ACTIVITY = 'enrol_activity',
  ENROL_ACTIVITY_SEARCH = 'enrol_activity_search',
  ENROL_SEARCH_TERM_REMOVE = 'enrol_search_term_remove',
  ENROL_ACTIVITY_SELECT = 'enrol_activity_select',
  ENROL_ACTIVITY_SELECT_ALL = 'enrol_activity_select_all',
  ENROL_REVIEW_CUSTOMERS = 'enrol_review_customers',
  ENROL_SORT = 'enrol_sort',
  ENROL_CUSTOMER_SEARCH = 'enrol_customer_search',
  ENROL_CUSTOMER_SELECT = 'enrol_customer_select',
  ENROL_CUSTOMER_REMOVE = 'enrol_customer_remove',
  ENROL_CUSTOMER_READD = 'enrol_customer_readd',
  ENROL_ADDITIONAL_CUSTOMERS = 'enrol_additional_customers',
  ENROL_ADDITIONAL_CUSTOMERS_EMAIL = 'enrol_additional_customers_email',
  ENROL_ADD_CUSTOMER = 'enrol_add_customer',
  ENROL_NEXT = 'enrol_next',
  ENROL_MESSAGE = 'enrol_message',
  ENROL_FOLLOWUP_ONE = 'enrol_followup_one',
  ENROL_FOLLOWUP_TWO = 'enrol_followup_two',
  ENROL_FOLLOWUP_THIRD = 'enrol_followup_third',
  ENROL_SEND_INVITES = 'enrol_send_invites',
  ENROL_DONE = 'enrol_done',
  ENROL_BACK = 'enrol_back',
  ENROL_CLOSE = 'enrol_close',

  CREATE_ADDON = 'create_addon',
  CUSTOMISE_ADDON_AVAILABILITY = 'customise_addon_availability',
  ADDON_AVAILABILITY_CHECK = 'addon_availability_check',
  CUSTOMISE_ADDON_AVAILABILITY_CONFIRM = 'customise_addon_availability_confirm',
  PAY_PER_ITEM = 'pay_per_item',
  PAY_PER_SESSION = 'pay_per_session',

  CUSTOM_MESSAGE_HEADER = 'custom_message_header',
  CUSTOM_MESSAGE_BODY = 'custom_message_body',
  CUSTOM_IMAGE_ADD = 'custom_image_add',

  VIEW_WAITLIST = 'view_waitlist',
  WAITLIST_BACK = 'waitlist_back',
  WAITLIST_DATE = 'waitlist_date',
  WAITLIST_TIME = 'waitlist_time',
  WAITLIST_WEEKDAY = 'waitlist_weekday',
  WAITLIST_CLEAR = 'waitlist_clear',
  WAITLIST_MANAGE_CAPACITY = 'waitlist_manage_capacity',
  WAITLIST_KEBAB = 'waitlist_kebab',
  WAITLIST_ADD_ACTIVITY = 'waitlist_add_activity',
  WAITLIST_DELETE = 'waitlist_delete',
  WAITLIST_DELETE_YES = 'waitlist_delete_yes',
  WAITLIST_DELETE_NO = 'waitlist_delete_no',
  WAITLIST_CUSTOMER_SELECT = 'waitlist_customer_select',
  WAITLIST_CUSTOMER_ALL = 'waitlist_customer_all',
  WAITLIST_INVITE = 'waitlist_invite',
  WAITLIST_MESSAGE = 'waitlist_message',
  WAITLIST_INVITE_SEND = 'waitlist_invite_send',
  WAITLIST_INVITE_DONE = 'waitlist_invite_done',
  WAITLIST_INVITE_CLOSE = 'waitlist_invite_close',
  WAITLIST_COPY_EMAIL = 'waitlist_copy_email',
  ADD_TO_WAITLIST = 'add_to_waitlist',
  ADD_WAITLIST_NAME = 'add_waitlist_name',
  ADD_WAITLIST_EMAIL = 'add_waitlist_email',
  ADD_WAITLIST_NUMBER = 'add_waitlist_number',
  ADD_WAITLIST_PLUS = 'add_waitlist_plus',
  ADD_WAITLIST_MINUS = 'add_waitlist_minus',
  ADD_WAITLIST_SESSION = 'add_waitlist_session',
  ADD_WAITLIST_CONFIRM = 'add_waitlist_confirm',
  ADD_WAITLIST_DONE = 'add_waitlist_done',

  BOOKINGS_TOGGLE = 'bookings_toggle',
  SUBS_TOGGLE = 'subs_toggle',
  SUB_ACTIVITY_FILTER = 'sub_activity_filter',
  SUB_LOCATION_FILTER = 'sub_location_filter',
  SUB_DAY_FILTER = 'sub_day_filter',
  SUB_TIME_FILTER = 'sub_time_filter',
  SUB_FILTER_CLEAR = 'sub_filter_clear',
  SUB_EXPORT = 'sub_export',

  FILTER_BUTTON_MOBILE = 'filter_button_mobile',
  BOOKINGS_SORT_MOBILE = 'bookings_sort_mobile',
  BOOKINGS_FILTERS_MOBILE = 'bookings_filters_mobile',
  SUB_ACTIVITY_FILTER_MOBILE = 'sub_activity_filter_mobile',
  SUB_LOCATION_FILTER_MOBILE = 'sub_location_filter_mobile',
  SUB_DAY_FILTER_MOBILE = 'sub_day_filter_mobile',
  SUB_TIME_FILTER_MOBILE = 'sub_time_filter_mobile',
  SUB_FILTER_CLEAR_MOBILE = 'sub_filter_clear_mobile',
  SUB_SHOWRESULTS_MOBILE = 'sub_showresults_mobile',

  BOOKING_ACTIVITY_FILTER_MOBILE = 'booking_activity_filter_mobile',
  BOOKING_LOCATION_FILTER_MOBILE = 'booking_location_filter_mobile',
  BOOKING_TIME_FILTER_MOBILE = 'booking_time_filter_mobile',
  BOOKING_FILTER_CLEAR_MOBILE = 'booking_filter_clear_mobile',
  BOOKING_SHOWRESULTS_MOBILE = 'booking_showresults_mobile',

  SCHEDULED_ACTIVITY_FILTER_MOBILE = 'scheduled_activity_filter_mobile',
  SCHEDULED_LOCATION_FILTER_MOBILE = 'scheduled_location_filter_mobile',
  SCHEDULED_FILTER_CLEAR_MOBILE = 'scheduled_filter_clear_mobile',
  SCHEDULED_SHOWRESULTS_MOBILE = 'scheduled_showresults_mobile',

  FREE_TRIAL_RADIO = 'free_trial_radio',
  PAID_TRIAL_RADIO = 'paid_trial_radio',
  NO_TRIAL_RADIO = 'no_trial_radio',
  TRIAL_LENGTH = 'trial_length',
  PAID_TRIAL_COST = 'paid_trial_cost',
  FUTURE_START_DATE = 'future_start_date',

  SPOTS_FILLED = 'spots_filled',
  SIDESHEET_WAITLIST = 'sidesheet_waitlist',
  ATTENDANCE_OVERVIEW_BACK = 'attendance_overview_back',
  VIEW_ACTIVITY_SUMMARY = 'view_activity_summary',
  ATTENDANCE_OVERVIEW_SEARCH = 'attendance_overview_search',
  ATTENDANCE_OVERVIEW_EXPORT = 'attendance_overview_export',
  ATTENDANCE_OVERVIEW_ATTENDED = 'attendance_overview_attended',
  ATTENDANCE_OVERVIEW_ABSENT = 'attendance_overview_absent',
  ATTENDANCE_OVERVIEW_ATTENDEE_INFO_MODAL = 'attendance_overview_attendee_info_modal',

  CANCEL_ATTENDEE_CTA = 'cancel_attendee_CTA',
  CANCEL_DATE = 'cancel_date',
  CANCEL_DATE_NEXT = 'cancel_date_next',
  CANCEL_OPTIONAL_REFUND = 'cancel_optional_refund',
  REFUND_ONLY = 'refund_only',
  CANCEL_ISSUE_REFUND = 'cancel_issue_refund',
  CANCEL_NO_REFUND = 'cancel_no_refund',
  REFUND_AMOUNT = 'refund_amount',
  REFUND_AMOUNT_NEXT = 'refund_amount_next',
  CANCEL_CUSTOMER_REQUESTED = 'cancel_customer_requested',
  CANCEL_SESSION_CANCELLED = 'cancel_session_cancelled',
  CANCEL_FULL_ACTIVITY = 'cancel_full_activity',
  CONFIRM_CANCELLATION = 'confirm_cancellation',
  CANCEL_STRIPE_TOPUP = 'cancel_stripe_topup',
  CANCEL_SUCCESS_DONE = 'cancel_success_done',
  CANCEL_MANUALLY_ADDED = 'cancel_manually_added',
  MOVED_CONTACT_SUPPORT = 'moved_contact_support',
  PEBBLE_PROMOTION_CONTACT_SUPPORT = 'pebble_promotion_contact_support',
  PONCHO_PAY_CONTACT_SUPPORT = 'poncho_pay_contact_support',
  SUB_FULL_CANCELLATIONS = 'sub_full_cancellations',
  SUB_CANCEL_CONTACT_SUPPORT = 'sub_cancel_contact_support',
  MANAGE_TRIAL_CTA = 'manage_trial_cta',

  CHECKINOUT_ON = 'checkinout_on',
  CHECKINOUT_OFF = 'checkinout_off',
  CHECKOUT = 'checkout',
  CHECKINOUT_TIMES_AO = 'checkinout_time_AO',
  CHECKINOUT_TIMES_REG = 'checkinout_time_reg',
  CHECKIN_TIME_UPDATE = 'checkin_time_update',
  CHECKOUT_TIME_UPDATE = 'checkout_time_update',
  CHECKINOUT_TIME_SAVE = 'checkinout_time_save',
  CHECKINOUT_TIME_CANCEL = 'checkinout_time_cancel',
  NOTES_TAB = 'notes_tab',
  NEW_NOTE = 'new_note',
  NOTE_UPDATE = 'note_update',
  NOTE_DELETE = 'note_delete',
  NOTE_DELETE_YES = 'note_delete_yes',
  NOTE_DELETE_CANCEL = 'note_delete_cancel',

  DISCOUNT_CODES = 'discount_codes',
  CREATE_DISCOUNT = 'create_discount',
  DISCOUNTS_UPGRADE = 'discounts_upgrade',
  ON_OFF_TOGGLE = 'on_off_toggle',
  FIXED = 'fixed',
  PERCENTAGE = 'percentage',
  SET_A_MAXIMUM_PRICE = 'set_a_maximum_price',
  VALID_FOR_ALL = 'valid_for_all',
  VALID_FOR_ACTIVITIES = 'valid_for_activities',
  VALID_FOR_CATEGORIES = 'valid_for_categories',
  TICKET_TYPE_INDIV = 'ticket_type_indiv',
  TICKET_TYPE_BLOCK = 'ticket_type_block',
  MAXIMUM_DISCOUNT_NUMBER = 'maximum_discount_number',
  MAXIMUM_DISCOUNT_UNLIMITED = 'maximum_discount_unlimited',
  MAXIMUM_PER_CUSTOMER_ONCE = 'maximum_per_customer_once',
  MAXIMUM_PER_CUSTOMER_UNLIMITED = 'maximum_per_customer_unlimited',
  ELIGIBILITY_ALL = 'eligibility_all',
  ELIGIBILITY_FIRST_TIME_BOOKERS = 'eligibility_first_time_bookers',
  MINIMUM_SESSIONS = 'minimum_sessions',
  MINIMUM_ATTENDEES = 'minimum_attendees',
  ACTIVE_START = 'active_start',
  ACTIVE_END = 'active_end',
  END_NEVER = 'end_never',
  EDIT_CODE = 'edit_code',
  REDEMPTIONS_ATTENDEE_INFORMATION = 'redemptions_attendee_information',
  REDEMPTIONS_SEARCH = 'redemptions_search',
  SAVE_DISCOUNT = 'save_discount',
  DISCOUNTS_FILTER_ON = 'discounts_filter_on',
  DISCOUNTS_FILTER_OFF = 'discounts_filter_off',
  DISCOUNTS_FILTER_EXPIRED = 'discounts_filter_expired',
  DISCOUNTS_FILTER_ALL = 'discounts_filter_all',

  SCHEDULE_NAME = 'schedule_name',
  SCHEDULE_DONE = 'schedule_done',
  SCHEDULE_EXPAND = 'schedule_expand',
  SCHEDULE_OPTIONS = 'schedule_options',
  SCHEDULE_CLONE = 'schedule_clone',
  SCHEDULE_EDIT = 'schedule_edit',
  SCHEDULE_DELETE_CONFIRM = 'schedule_delete_confirm',
  SCHEDULE_DELETE = 'schedule_delete',
  SCHEDULE_DELETE_CANCEL = 'schedule_delete_cancel',
  SCHEDULE_UPCOMING = 'schedule_upcoming',
  SCHEDULE_PAST = 'schedule_past',
  SCHEDULE_CAPACITY_SELECT = 'schedule_capacity_select',
  SCHEDULE_TICKETS = 'schedule_tickets',
  SCHEDULE_TICKETS_SELECT = 'schedule_tickets_select',
  SCHEDULE_ADDON = 'schedule_addon',
  SCHEDULE_ADDON_SELECT = 'schedule_addon_select',
  AO_SCHEDULES_ALL = 'AO_schedules_all',
  AO_SCHEDULES_PAST = 'AO_schedules_past',
  AO_SCHEDULES_UPCOMING = 'AO_schedules_upcoming',
  AO_SCHEDULES = 'AO_schedules',

  CREATE_SCHEDULE = 'create_schedule',

  NO_TRIAL_BLOCK = 'no_trial_block',
  FREE_TRIAL_BLOCK = 'free_trial_block',
  PAID_TRIAL_BLOCK = 'paid_trial_block',
  TRIAL_BLOCK_NUMBER = 'trial_block_number',
  TRIAL_BLOCK_COST = 'trial_block_cost',
  TRIAL_ACTION_REQUIRED = 'trial_action_required',
  FAILED_PAYMENTS_CUSTOMER = 'failed_payments_customer',
  FAILED_PAYMENTS_CANCEL_BOOKING = 'failed_payments_cancel_booking',
  FAILED_PAYMENTS_RETRY_PAYMENTS = 'failed_payments_retry_payments',
  FAILED_PAYMENTS_RETRY_PAYMENTS_YES = 'failed_payments_retry_payments_yes',
  FAILED_PAYMENTS_REMOVE_NOTIFICATION = 'failed_payments_remove_notification',
  TRIAL_CANCEL_CONTINUE = 'trial_cancel_continue',
  TRIAL_CANCEL_REASON = 'trial_cancel_reason',
  TRIAL_CANCEL_CONFIRM = 'trial_cancel_confirm',

  CUSTOMERS_SELECTED = 'customers',
  CUSTOMER_PROFILE = 'customer_profile',
  BOOKING_HISTORY = 'booking_history',
  SHOW_ATTENDEE_NAMES = 'show_attendee_names',
  CUSTOMER_SEARCH = 'customer_search',
  CUSTOMERS_EMAIL = 'customers_email',
  CUSTOMERS_PHONE = 'customers_phone',
  CUSTOMER_COPY = 'customer_copy',
  CUSTOMERS_BOOKING_ACTIVITY = 'customers_booking_activity',
  CUSTOMERS_BOOKING_ATTENDEE = 'customers_booking_attendee',

  MESSAGE_WHATSAPP = 'message_whatsapp',
  MESSAGE_SMS = 'message_sms',
  MESSAGE_EMAIL = 'message_email',
  MESSAGING_COPY_ALL_NUMBERS = 'messaging_copy_all_numbers',
  MESSAGING_COPY_ALL_EMAILS = 'messaging_copy_all_emails',
  MESSAGING_TOGGLE_ATTENDEES = 'messaging_toggle_attendees',
  MESSAGING_INCLUDE_OPTOUTS = 'messaging_include_optouts',
  MESSAGING_COPY_ONE_NUMBER = 'messaging_copy_one_number',
  MESSAGING_COPY_ONE_EMAIL = 'messaging_copy_one_email',
  MESSAGE_REPLYTO = 'message_replyto',
  MESSAGE_SL = 'message_SL',
  MESSAGE_COPY = 'message_copy',
  MESSAGE_ATTACHMENT = 'message_attachment',
  MESSAGE_IMAGE = 'message_image',
  MESSAGE_CHOOSE_FILE = 'message_choose_file',
  MESSAGE_ATTACHMENT_CLEAR = 'message_attachment_clear',
  MESSAGE_SEND = 'message_send',
  MESSAGE_SUCCESS = 'message_success',
  MESSAGE_FAILURE = 'message_failure',

  DIRECTORY_FILTERS = 'directory_filters',
  DIRECTORY_ACTIVITY_FILTER = 'directory_activity_filter',
  DIRECTORY_BOOKING_STATUS_FILTER = 'directory_bookingstatus_filter',
  DIRECTORY_FILTERS_APPLY = 'directory_filters_apply',
  DIRECTORY_FILTERS_RESET = 'directory_filters_reset',
  DIRECTORY_FILTER_REMOVE = 'directory_filter_remove',
  CUSTOMER_DIRECTORY_MESSAGE = 'customer_directory_message',

  TAG_ADD = 'tag_add',
  TAG_REMOVE = 'tag_remove',
  TAG_FIELDS = 'tag_fields',

  COMMUNICATIONS_SELECTED = 'communications_selected',
  PREVIEW_EMAIL = 'preview_email',
  SAVE_REPLYTO = 'save_replyto',
  GLOBAL_HEADER_IMAGE = 'global_header_image',

  BOOKING_CONFIRMATION_FURTHERINFO = 'booking_confirmation_furtherinfo',
  SESSION_REMINDER_FURTHERINFO = 'session_reminder_furtherinfo',
  SESSION_REMINDER_TOGGLE = 'session_reminder_toggle',

  EMAIL_LEGALS_AGREE = 'email_legals_agree',
  EMAIL_LEGALS_CANCEL = 'email_legals_cancel',

  MANAGE_INVOICES = 'manage_invoices',
  CREATE_INVOICE = 'create_invoice',
  INVOICE_CUSTOMER = 'invoice_customer',
  INVOICE_CUSTOMER_EMAIL = 'invoice_customer_email',
  INVOICE_SELECT = 'invoice_select',
  INVOICE_RECIPIENT_NAME = 'invoice_recipient_name',
  INVOICE_RECIPIENT_EMAIL = 'invoice_recipient_email',
  INVOICE_ITEM = 'invoice_item',
  INVOICE_QUANTITY = 'invoice_quantity',
  INVOICE_COST = 'invoice_cost',
  INVOICE_DUE = 'invoice_due',
  INVOICE_DESCRIPTION = 'invoice_description',
  INVOICE_SAVE = 'invoice_save',
  INVOICE_COPY = 'invoice_copy',
  INVOICE_DONE = 'invoice_done',
  INVOICE_SEND = 'invoice_send',
  INVOICE_OPTIONS = 'invoice_options',
  INVOICE_OPTIONS_SEND = 'invoice_options_send',
  INVOICE_OPTIONS_COPY = 'invoice_options_copy',
  INVOICE_OPTIONS_MARK_PAID = 'invoice_options_mark_paid',
  INVOICE_OPTIONS_VOID = 'invoice_options_void',
  INVOICE_OPTIONS_PAID_CANCEL = 'invoice_options_paid_cancel',
  INVOICE_OPTIONS_PAID_CONFIRM = 'invoice_options_paid_confirm',
  INVOICE_OPTIONS_VOID_CANCEL = 'invoice_options_void_cancel',
  INVOICE_OPTIONS_VOID_CONFIRM = 'invoice_options_void_confirm',

  ENABLE_WELCOME_EMAIL = 'enable_welcome_email',
  ENABLE_BIRTHDAY_EMAIL = 'enable_birthday_email',
  ENABLE_THANKYOU_EMAIL = 'enable_thankyou_email',
  DISABLE_WELCOME_EMAIL = 'disable_welcome_email',
  DISABLE_BIRTHDAY_EMAIL = 'disable_birthday_email',
  DISABLE_THANKYOU_EMAIL = 'disable_thankyou_email',

  CUSTOMISE_REGISTER = 'customise_register',
  REGISTER_ACTIONS_MENU = 'register_actions_menu',
  REGISTER_TOGGLE_ATTENDEE_INFO = 'register_toggle_attendee_info',

  SESSION_ADDON_MANAGE = 'session_add_on_manage',
  ITEM_ADDON_MANAGE = 'item_add_on_manage',
  MANAGE_ADDON_SAVE = 'manage_add_on_save',
}
